<template>
    <div class="kt-portlet">
        <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="row row-no-padding row-col-separator-xl">
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <!--begin:: Widgets/Stats2-1 -->
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info" data-cy="home_widget_info_sites">
                                <h3 class="kt-widget1__title">{{ $t("site_sites") }}</h3>
                                <span class="kt-widget1__desc">{{ $t("site_numberOfUserSites") }}</span>
                            </div>
                            <span
                                class="kt-widget1__number kt-font-brand"
                                style=" font-size: 2rem; "
                            >{{ numberOfUserSites }}</span>
                        </div>
                    </div>

                    <!--end:: Widgets/Stats2-1 -->
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <!--begin:: Widgets/Stats2-2 -->
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div
                                class="kt-widget1__info"
                                data-cy="home_widget_info_sitesInProduction"
                            >
                                <h3 class="kt-widget1__title">{{ $t("site_sitesInProduction") }}</h3>
                                <span
                                    class="kt-widget1__desc"
                                >{{ $t("site_numberOfUserSitesInProduction") }}</span>
                            </div>
                            <span
                                class="kt-widget1__number kt-font-success"
                                style=" font-size: 2rem; "
                            >{{ numberOfUserSitesInProduction }}</span>
                        </div>
                    </div>

                    <!--end:: Widgets/Stats2-2 -->
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                    <!--begin:: Widgets/Stats2-3 -->
                    <div class="kt-widget1">
                        <div class="kt-widget1__item">
                            <div class="kt-widget1__info" data-cy="home_widget_info_Applications">
                                <h3 class="kt-widget1__title">{{ $t("app_applications") }}</h3>
                                <span
                                    class="kt-widget1__desc"
                                >{{ $t("app_numberOfUserApplications") }}</span>
                            </div>
                            <span
                                class="kt-widget1__number kt-font-danger"
                                style=" font-size: 2rem; "
                            >{{ numberOfUserApplications }}</span>
                        </div>
                    </div>

                    <!--end:: Widgets/Stats2-3 -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {

    created() {
        this.getUserApplications();
    },

    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "numberOfUserSites",
            "numberOfUserSitesInProduction",
            "numberOfUserApplications"
        ])
    },

    methods: {
        ...mapActions(["getUserApplications"])
    }
};
</script>

<style></style>

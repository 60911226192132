<template>
    <div id="home" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">

                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">
                                    {{ $t("site_sitesDashboard") }}
                                </h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("site_manageSitesSubTitle") }}</span>
                            </div>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link :to="{ name: 'newsite' }" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10" data-cy="createNewSite">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("site_createNewSite") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="kt-content kt-grid__item kt-grid__item--fluid" v-if="isDisplayedBanner">
                                <div class="row marginRowLeftAndRightZero">
                                    <div class="col-lg-12 alert alert-custom alert-light alert-outline-info alert-elevate" role="alert" style="font-weight: 500;">
                                        <div class="alert-icon">
                                            <i class="flaticon-information kt-font-brand"></i>
                                        </div>
                                        <div class="alert-text" v-html="currentBannerMessage">
                                        </div>
                                        <div class="alert-close kt-font-bolder">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true"><i class="la la-close"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <app-globalcounterswidget></app-globalcounterswidget>
                            <div class="row">
                                <app-sites></app-sites>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GlobalCounters from "./globalcounters.vue";
import SitesTable from "./sitestable.vue";

export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            isDisplayedBanner: false,
            bannerFrenchMessage: "",
            bannerEnglishMessage: "",
            currentBannerMessage: "<p>sdklskds</p>",
            releaseNoteUrl: window.location.origin + "/doc/OmniAccess_Stellar_Asset_Tracking_Production_Notes.pdf" || "https://www.omniaccess-stellar-asset-tracking.com/doc/OmniAccess_Stellar_Asset_Tracking_Production_Notes.pdf"
        };
    },
    created: function() {
        console.log("Component(Home)::created() - called");
        if (this.createAnotherSite) {
            this.$router.push({ name: "newsite" });
        }
        this.getBannerMessage();
    },
    mounted: function() {
        console.log("Component(Home)::mounted() - Init metronic layout");
        // Init metronic layout
        KTLayout.init();
        //$("#subcriptionactiviationmodal").modal("show");
    },
    destroyed: function() {
        console.log("Component(Home)::destroyed() - called");
        this.resetBroadcastState();
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user", "createAnotherSite", "diplayMessagesOfBanner"])
    },
    watch: {
        user: function(user) {
            console.log("Component(Home)::watch(user) called with : ", user);
            // Update the message of banner to display
            this.updateBannerMessage();
        },
        diplayMessagesOfBanner: function(diplayMessagesOfBanner) {
            console.log("Component(Home)::watch(diplayMessagesOfBanner) called with : ", diplayMessagesOfBanner);
            if (diplayMessagesOfBanner) {
                this.isDisplayedBanner = true;
                // Build the message to displayed in different languages
                this.bannerFrenchMessage = this.getDisplayHtml(diplayMessagesOfBanner.french, "fr");
                this.bannerEnglishMessage = this.getDisplayHtml(diplayMessagesOfBanner.english, "en");
                // Update the message of banner to display
                this.updateBannerMessage();
            } else {
                this.isDisplayedBanner = false;
                // Set the translation to french of banner message to empty
                this.bannerFrenchMessage = "";
                // Set the translation to english of banner message to empty
                this.bannerEnglishMessage = "";
                // Set to empty the current banner message
                this.currentBannerMessage = "";
            }
        }
    },

    methods: {
        ...mapActions(["getBannerMessage", "resetBroadcastState"]),

        /**
         * Method to build the html sting to display
         */
        getDisplayHtml(inputString, language) {
            // Create url verification regex
            const regexUrl =/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            // Split the message to create array
            const arrayRows = inputString.split("\n");
            let responseHtml = "";
            for(let row of arrayRows) {
                if (row == "") {
                    responseHtml += "<br />";
                } else {
                    // Analyse the row string to verify if line contains the url
                    const rowStrings = row.split(" ");
                    let displayRow = "";
                    for (let currentString of rowStrings) {
                        if (regexUrl.test(currentString)) {
                            // Add the element link in string
                            displayRow += ` <a class="kt-link" target="_blank" href="${currentString}">${currentString}</a>`;
                        } else {
                            // Normal string add it
                            displayRow += " " + currentString;
                        }
                    }
                    responseHtml += `<span>${displayRow}</span><br />`;
                }
            }
            // Add the end of message
            if (language == "fr") {
                responseHtml += "<span>L'équipe d'OmniAccess Stellar Asset Tracking</span>";
            } else {
                responseHtml += "<span>The OmniAccess Stellar Asset Tracking team</span>";
            }
            // Return the response in html
            return responseHtml;
        },

        /**
         * Method which permit to update the banner message
         */
        updateBannerMessage() {
            // Set the current message to display according to user current language
            if (this.user && this.user.preferredLanguage === "fr") {
                this.currentBannerMessage = this.bannerFrenchMessage;
            } else {
                this.currentBannerMessage = this.bannerEnglishMessage;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-globalcounterswidget": GlobalCounters,
        "app-sites": SitesTable
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>

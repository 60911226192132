<template>
    <div v-bind:class="[this.releasedFor == 'HAN' ? 'col-xl-12' : 'col-xl-12', '']">
        <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24" />
                                <path d="M10.5,5 L19.5,5 C20.3284271,5 21,5.67157288 21,6.5 C21,7.32842712 20.3284271,8 19.5,8 L10.5,8 C9.67157288,8 9,7.32842712 9,6.5 C9,5.67157288 9.67157288,5 10.5,5 Z M10.5,10 L19.5,10 C20.3284271,10 21,10.6715729 21,11.5 C21,12.3284271 20.3284271,13 19.5,13 L10.5,13 C9.67157288,13 9,12.3284271 9,11.5 C9,10.6715729 9.67157288,10 10.5,10 Z M10.5,15 L19.5,15 C20.3284271,15 21,15.6715729 21,16.5 C21,17.3284271 20.3284271,18 19.5,18 L10.5,18 C9.67157288,18 9,17.3284271 9,16.5 C9,15.6715729 9.67157288,15 10.5,15 Z" fill="#000000" />
                                <path d="M5.5,8 C4.67157288,8 4,7.32842712 4,6.5 C4,5.67157288 4.67157288,5 5.5,5 C6.32842712,5 7,5.67157288 7,6.5 C7,7.32842712 6.32842712,8 5.5,8 Z M5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 C6.32842712,10 7,10.6715729 7,11.5 C7,12.3284271 6.32842712,13 5.5,13 Z M5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 C6.32842712,15 7,15.6715729 7,16.5 C7,17.3284271 6.32842712,18 5.5,18 Z" fill="#000000" opacity="0.3" />
                            </g>
                        </svg>
                    </span>
                    <h3 class="kt-portlet__head-title">{{ $t("site_sitesList") }}</h3>
                </div>
                <div
                    v-if="!isEmptyList"
                    class="kt-portlet__head-toolbar"
                >
                    <div class="kt-portlet__head-wrapper">
                        <div class="kt-portlet__head-toolbar">
                            <div class="row align-items-center">
                                <div
                                    class="col-md-12 kt-margin-b-20-tablet-and-mobile"
                                >
                                    <div
                                        class="kt-input-icon kt-input-icon--left"
                                    >
                                        <input
                                            type="text"
                                            :placeholder="$t('common_searchByNameOrTypeStatus')"
                                            id="sites_searchInput" @keyup="onSearch" v-model="searchedValue"
                                            class="searchInputDatatable form-control form-control-sm width270"
                                        />
                                        <span
                                            class="kt-input-icon__icon kt-input-icon__icon--left"
                                        >
                                            <span>
                                                <i class="la la-search"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--begin emptyList component-->
            <app-emptylist targetObjectName="SITE" mainTextKey="site_notFound" subTextKey="site_notFoundSubLabel" imgName="empty_site.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
            <!--end emptyList component-->
            <div class="kt-portlet__body kt-portlet__body--fit">
                <!--begin: Datatable -->
                <div class="kt-datatable" id="sites_datatable"></div>
                <!--end: Datatable -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            sitesTable: null,
            sitesData: null,
            searchedValue: "",
            sitesById: {},
            isEmptyList: false
        };
    },
    created: function() {
        console.log("Component(SitesTable)::created() - called");
        // Load user sites from backend and store the result in sites store.
        let data = {
            includeImage: false
        }
        this.getUserSites(data);
    },
    destroyed: function() {
        console.log("Component(SitesTable)::destroyed() - called");
        this.resetSitesState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        user(user) {
            console.log("Component(SitesTable)::watch(user) - called with : ", user);
            if (user) {
                // In case of reload user (as for a language change)
                this.sitesData = this.buildDatatableData();
                this.initKtDataTable(this.sitesData);
            }
        },

        userSites: function(sites) {
            console.log("Component(SitesTable)::watch(userSites) - called with : ", sites);
            if (this.isAdminUser()) {
                // Load datatable with all sites
                this.sitesData = this.buildDatatableData();
                this.initKtDataTable(this.sitesData);
            } else {
                // Get logged user roles of all user sites before loading sites datatable
                this.getUserRolesAndSites();
            }
        },

        rolesBySiteId: function(rolesBySiteId) {
            console.log("Component(SitesTable)::watch(rolesBySiteId) - called with : ", rolesBySiteId);
            this.sitesData = this.buildDatatableData();
            this.initKtDataTable(this.sitesData);
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["userSites", "user", "rolesBySiteId"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getUserSites",
            "getUserRolesAndSites",
            "setCurrentSiteId",
            "showDeleteModal",
            "resetSitesState"
        ]),

        onViewSite: function(siteId) {
            this.$router.push({
                name: "siteOverview",
                params: { siteId: siteId }
            });
        },

        onSearch() {
            if (this.sitesTable) {
                const searchedValueUpperCase = this.searchedValue.toUpperCase();
                // To search EITHER by name OR by macAddress:
                // 1. Search in "name" field of sites data
                const dataFoundByName = _.filter(this.sitesData, site => site && site.name && site.name.toUpperCase().includes(searchedValueUpperCase));
                // 2. Search in "type" field of sites data
                const dataFoundByType = _.filter(this.sitesData, site => site && site.siteType && site.siteType.name && site.siteType.name.toUpperCase().includes(searchedValueUpperCase));
                // 3. Search in "siteState" field of sites data
                const dataFoundByState = _.filter(this.sitesData, site => site && site.siteState && site.siteState.toUpperCase().includes(searchedValueUpperCase));
                // 4. Search in "subscriptionType" field of sites data
                const dataFoundBySubType = _.filter(this.sitesData, site => site && site.subscriptionType && site.subscriptionType.toUpperCase().includes(searchedValueUpperCase));
                // 5. Create an array with previous unique resulting searched
                let dataFound = _.unionBy(dataFoundByName, dataFoundByType, 'id');
                dataFound = _.unionBy(dataFound, dataFoundByState, 'id');
                dataFound = _.unionBy(dataFound, dataFoundBySubType, 'id');
                if (dataFound && dataFound.length > 0) {
                    // Reload datatable with only rows which contain searchedValue
                    this.initKtDataTable(dataFound, this.sitesTable.getPageSize());
                } else {
                    // No searchedValue found => Display empty datatable
                    this.sitesTable.search(this.searchedValue, "unknown");
                }
            }
        },

        registerEventsOnSitesTable() {

            let self = this;

            $("#sites_datatable").on("kt-datatable--on-layout-updated", () => {

                $(".deleteSiteButton").off().on("click", function() {
                    let siteId = $(this).attr("data-siteid");
                    let siteName = $(this).attr("data-sitename");
                    if (siteName && siteName.length > 20) {
                        siteName = siteName.substring(0, 20) + "...";
                    }
                    console.log("Component(SitesTable)::registerEventsOnSitesTable(deleteSiteBustton) clicked. SiteId = " +siteId);
                    self.showDeleteModal({
                        textContent: i18n.t("site_deleteSiteConfirmText", {
                            siteName: siteName
                        }),
                        actionName: "DELETE_SITE",
                        params: { siteId: siteId }
                    });
                });

                $(".editSiteButton").off().on("click", function() {
                    let siteId = $(this).attr("data-siteid");
                    console.log("Component(SitesTable)::registerEventsOnSitesTable(editSiteButton) clicked. SiteId = " +siteId);
                    // Hide and destroy element’s tooltip
                    $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                    // Go to overview vue
                    self.onViewSite(siteId);
                });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({
                        trigger: "hover"
                    });
                }, 50);

            });

        },

        buildDatatableData() {
            // Internal search function works with source data
            // For search some string which must give the translate string on source data not key
            let dataSource = [];
            if (this.userSites) {
                for (let site of this.userSites) {
                    let siteType = "";
                    if (site.siteType) {
                        siteType = site.siteType.isDefault ? i18n.t(site.siteType.name) : site.siteType.name;
                    }
                    dataSource.push({
                        id: site.id,
                        name: site.name,
                        type: siteType,
                        updatedAt: site.updatedAt,
                        isInProduction: site.isInProduction,
                        siteState: site.isInProduction ? i18n.t("site_statusProduction") : i18n.t("site_statusNotDeploy"),
                        subscription: site.subscription,
                        subscriptionType: i18n.t("subscription_" + commonVueHelper.getSubscriptionStatus(site.subscription)),
                        expiredAccessAt: site.expiredAccessAt
                    });
                }
            }
            return dataSource;
        },

        initKtDataTable(dataSource, pageSize) {
            let self = this;

            // Now initialize datatables with data source
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(dataSource),
                    pageSize: (pageSize ? pageSize : 5),
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: true,
                pagination: !0,
                columns: [
                    {
                        field: "name",
                        title: i18n.t("site_siteName"),
                        overflow: "visible",
                        textAlign: "center",
                        autoHide: false,
                        template: function(row) {
                            let siteName = row.name;
                            if (row.name.length > 20) {
                                let maxLength = 20;
                                siteName = row.name.substring(0, maxLength) + "...";
                            }
                            return (
                                '\
                                <div class="kt-user-card-v2" data-toggle="kt-tooltip" data-placement="top" data-original-title="' + row.name + '">\
                                    <div class="kt-user-card-v2__details openResourceLink">\
                                        <a class="editSiteButton kt-user-card-v2__name kt-link" data-siteid="' +
                                row.id +
                                '" href="javascript:;">' +
                                siteName +
                                "</a>\
                                    </div>\
                                </div>\
                            "
                            );
                        }
                    },
                    {
                        field: "type",
                        title: i18n.t("site_siteType"),
                        overflow: "visible",
                        textAlign: "center",
                        autoHide: false
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        textAlign: "center",
                        sortable: "desc",
                        width: 170,
                        template: function(row) {
                            if (row.updatedAt) {
                                return moment(row.updatedAt).format(
                                    commonVueHelper.getDateStringFormatFromLocale(
                                        moment().locale()
                                    )
                                );
                            }
                        }
                    },
                    {
                        field: "siteState",
                        title: i18n.t("common_status"),
                        overflow: "visible",
                        textAlign: "center",
                        autoHide: false,
                        width: 180,
                        template: function(row) {
                            if (row.isInProduction) {
                                return (
                                    '<span class="kt-badge kt-badge--success kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-success">' +
                                    row.siteState +
                                    "</span>"
                                );
                            } else {
                                return (
                                    '<span class="kt-badge kt-badge--brand kt-badge--dot"></span>&nbsp;<span class="kt-font-bold kt-font-brand">' +
                                    row.siteState +
                                    "</span>"
                                );
                            }
                        }
                    },
                    {
                        field: "subscriptionType",
                        title: i18n.t("subscription_homeTableTitle"),
                        overflow: "visible",
                        width: 140,
                        textAlign: "center",
                        autoHide: false,
                        sortCallback: function (data, sort, column) {
                            let sorted = $(data).sort(function (a, b) {
                                let subscriptionTypeA = commonVueHelper.getSubscriptionStatus(a.subscription);
                                let aField, bField;
                                if (subscriptionTypeA){
                                    aField = i18n.t("subscription_"+subscriptionTypeA)
                                } else {
                                    aField = i18n.t("subscription_typeNoSubscription")
                                }
                                let subscriptionTypeB = commonVueHelper.getSubscriptionStatus(b.subscription);
                                if (subscriptionTypeB){
                                    bField = i18n.t("subscription_"+subscriptionTypeB)
                                } else {
                                    bField = i18n.t("subscription_typeNoSubscription")
                                }
                                if (sort === 'asc') {
                                    return aField < bField ? 1 : aField > bField ? -1 : 0;
                                } else {
                                    return aField > bField ? 1 : aField < bField ? -1 : 0;
                                }
                            });
                            return sorted;
                        },
                        template: function(row) {
                            let subscriptionType = commonVueHelper.getSubscriptionStatus(row.subscription);
                            switch (subscriptionType) {
                                case "TRIAL_EXPIRED":
                                    return (
                                        '<span class="subscriptionType kt-badge kt-badge--inline kt-badge--danger">' +
                                        row.subscriptionType +
                                        "</span>"
                                    );
                                case "TRIAL_ACTIVE":
                                    return (
                                        '<span class="subscriptionType kt-badge kt-badge--inline kt-badge--success">' +
                                        row.subscriptionType +
                                        "</span>"
                                    );
                                case "PAID_EXPIRED":
                                    return (
                                        '<span class="subscriptionType kt-badge kt-badge--inline kt-badge--danger">' +
                                        row.subscriptionType +
                                        "</span>"
                                    );
                                case "PAID_ACTIVE":
                                    return (
                                        '<span class="subscriptionType kt-badge kt-badge--inline kt-badge--success">' +
                                        row.subscriptionType +
                                        "</span>"
                                    );
                                default:
                                    return (
                                        '<span class="subscriptionType kt-badge kt-badge--inline kt-badge--gray">' +
                                        i18n.t(
                                            "subscription_typeNoSubscription"
                                        ) +
                                        "</span>"
                                    );
                            }
                        }
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            let htmlButtonDelete = ``;
                            let currentTime = new Date();
                            if(row.expiredAccessAt != -1 && new Date(row.expiredAccessAt) <= currentTime){
                                return "-";
                            }else{
                                if (self.rolesBySiteId[row.id] === 'ADMIN' || self.isAdminUser()) {
                                    // Display button delete site only if user has the ADMIN role on the site
                                    htmlButtonDelete =
                                    `<button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_delete") +
                                    `" type="button" data-siteid="` + row.id + `" data-sitename="` + row.name +
                                    `" class="btn btn-outline-danger btn-sm btn-icon deleteSiteButton">
                                        <i class="la la-trash"></i>
                                    </button>`
                                }

                                return (
                                    `
                                    <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` + i18n.t("common_edit") +
                                    `" type="button" data-siteid="` + row.id +
                                    `" class="btn btn-outline-brand btn-sm btn-icon editSiteButton">
                                        <i class="la la-pencil"></i>
                                    </button>
                                    `+
                                    htmlButtonDelete
                                );
                            }
                            
                        }
                    }
                ]
            };

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.sitesTable) {
                $("#sites_datatable")
                    .KTDatatable()
                    .destroy();
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.sitesTable = $("#sites_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSitesTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
                this.sitesTable = null;
            }
        },

        isAdminUser() {
            if (this.user) {
                if (this.user.isSuperAdmin || this.user.isTSUser) {
                    return true;
                }
            }
            return false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList
    }
};
</script>

<style>
.subscriptionType {
    font-weight: 600;
}
</style>
